<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="change-password-modal-label">{{ $root.text('Change password', null, true) }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label for="current_password" id="current_password-label" class="mandatory-label">
                            {{ $root.text('Enter your current password', null, true) }} *
                        </label>
                        <input
                            v-model="current_password"
                            type="password"
                            id="current_password"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('current_password')"
                            v-on:change="$root.unHighlightErrors('current_password')"
                        >
                    </div>
                    <div class="form-group">
                        <a
                            href="#"
                            data-toggle="modal"
                            data-target="#forgotten-password-modal"
                            @click="resetForgottenPasswordForm()"
                        >
                            <i class="far fa-lock-alt mr-1"></i>
                            {{ $root.text('Forgot your current password?', null, true) }}
                        </a>
                    </div>
                    <div v-if="current_password" class="form-group">
                        <label for="new_password" id="new_password-label" class="mandatory-label">
                            {{ $root.text('New password', null, true) }} *
                        </label>
                        <input
                            v-model="new_password"
                            type="password"
                            id="new_password"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('new_password')"
                            v-on:change="$root.unHighlightErrors('new_password')"
                        >
                        <password-strength
                            :password="new_password"
                            v-model="new_password_score"
                            :label_not_strong_enough="$root.text('not strong enough', null, true)"
                            :label_ok="$root.text('OK', null, true)"
                        ></password-strength>
                    </div>
                    <div v-if="current_password" class="form-group">
                        <label for="confirm_new_password" id="confirm_new_password-label" class="mandatory-label">
                            {{ $root.text('Confirm new password', null, true) }} *
                        </label>
                        <input
                            v-model="confirm_new_password"
                            type="password"
                            id="confirm_new_password"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('confirm_new_password')"
                            v-on:change="$root.unHighlightErrors('confirm_new_password')"
                        >
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="onSubmit()"
                        :disabled="(!new_password || !current_password || !confirm_new_password
                            || (new_password_score < 7))"
                    >
                        <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                current_password: '',
                new_password: '',
                confirm_new_password: '',
                new_password_score: 0,
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.current_password = '';
                this.new_password = '';
                this.confirm_new_password = '';
            },
            onSubmit: function () {
                this.updating = true;
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API.
                 */
                const data = {
                    current_password: this.current_password,
                    new_password: this.new_password,
                    confirm_new_password: this.confirm_new_password
                };

                const path = '/' + this.$root.app.client_id + '/user/change-password';
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                    }

                    setTimeout(function () {
                        $('#change-password-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#change-password-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = vm.$root.text(error.response.data.error);
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += vm.$root.parseApiErrors(key, value);
                                    $('#' + key + '-label').addClass('text-danger');
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            resetForgottenPasswordForm: function () {
                $('#change-password-modal').modal('hide');
                const component = this.$root.findComponent(this.$parent, 'forgotten-password');
                if (component) {
                    component.resetForm();
                }
            }
        }
    }
</script>
