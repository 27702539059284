import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Alerts from './components/settings/Alerts';
import App from './components/App';
import ArtworkApprovals from './components/templates/ArtworkApprovals';
import Assets from './components/assets/Assets';
import AssetApprovals from './components/assets/AssetApprovals';
import AssetReviews from './components/assets/AssetReviews';
import AssetTypes from './components/assets/AssetTypes';
import AssetTypesInactive from './components/assets/AssetTypesInactive';
import Collections from './components/collections/Collections';
import ConfirmAvailability from './components/ConfirmAvailability';
import CustomFields from './components/assets/CustomFields';
import DeletionRequests from './components/users/DeletionRequests';
import DetectedLabelExclusions from './components/assets/DetectedLabelExclusions';
import Folders from './components/assets/Folders';
import FoldersArchived from './components/assets/FoldersArchived';
import Home from './components/home/Home';
import KeywordCategories from './components/assets/KeywordCategories';
import Languages from './components/settings/Languages';
import Login from './components/Login';
import MyAccount from './components/my-account/MyAccount';
import MyAccountArtworkActions from './components/my-account/MyAccountArtworkActions';
import MyAccountArtworkApprovals from './components/my-account/MyAccountArtworkApprovals';
import MyAccountAssetsApproved from './components/my-account/MyAccountAssetsApproved';
import MyAccountAssetsAwaitingApproval from './components/my-account/MyAccountAssetsAwaitingApproval';
import MyAccountCollectionsShared from './components/my-account/MyAccountCollectionsShared';
import MyAccountDownloads from './components/my-account/MyAccountDownloads';
import MyAccountFlytesSent from './components/my-account/MyAccountFlytesSent';
import MyAccountUploads from './components/my-account/MyAccountUploads';
import MyAccountUserGroups from './components/my-account/MyAccountUserGroups';
import Notifications from './components/notifications/Notifications';
import PodCodes from './components/pod-codes/PodCodes';
import Proofs from './components/proofs/Proofs';
import PreApprovedEmailDomains from './components/users/PreApprovedEmailDomains';
import PricePanelTypes from './components/print-orders/PricePanelTypes';
import PrintOrders from './components/print-orders/PrintOrders';
import PrintOrderSuppliers from './components/print-orders/PrintOrderSuppliers';
import PrintOrderCopyRecipients from './components/print-orders/PrintOrderCopyRecipients';
import ProfileFields from './components/templates/ProfileFields';
import ProfileGroups from './components/templates/ProfileGroups';
import Reports from './components/settings/Reports';
import SetPassword from './components/SetPassword';
import Settings from './components/settings/Settings';
import Shared from './components/Shared';
import SingleSignOn438 from './components/users/SingleSignOn438';
import SingleSignOnSaml from './components/users/SingleSignOnSaml';
import SupplierPrintOrderFileDownload from './components/print-orders/SupplierPrintOrderFileDownload';
import Support from './components/Support';
import Users from './components/users/Users';
import UserGroups from './components/users/UserGroups';
import VerifyEmail from './components/VerifyEmail';
import ViewCollection from './components/collections/ViewCollection';
import ViewProfileGroup from './components/templates/ViewProfileGroup';
import ViewProof from './components/proofs/ViewProof';

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/collections',
            name: 'collections',
            component: Collections
        },
        {
            path: '/collections/:id',
            name: 'view-collection',
            component: ViewCollection
        },
        {
            path: '/confirm-availability/:email',
            name: 'confirm-availability',
            component: ConfirmAvailability
        },
        {
            path: '/get-new-invite-link/:code',
            name: 'get-new-invite-link',
            component: SetPassword
        },
        {
            path: '/get-new-reset-link/:code',
            name: 'get-new-reset-link',
            component: SetPassword
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/login/:password_reset_user_data',
            name: 'login-password-reset',
            component: Login
        },
        {
            path: '/my-account',
            name: 'my-account',
            component: MyAccount
        },
        {
            path: '/my-account/collections-shared',
            name: 'my-account-collections-shared',
            component: MyAccountCollectionsShared
        },
        {
            path: '/my-account/downloads',
            name: 'my-account-downloads',
            component: MyAccountDownloads
        },
        {
            path: '/my-account/flytes-sent',
            name: 'my-account-flytes-sent',
            component: MyAccountFlytesSent
        },
        {
            path: '/my-account/print-orders',
            name: 'my-account-print-orders',
            component: PrintOrders
        },
        {
            path: '/my-account/print-orders/:filters',
            name: 'my-account-print-orders-filtered',
            component: PrintOrders
        },
        {
            path: '/my-account/artwork-approval-history',
            name: 'my-account-artwork-actions',
            component: MyAccountArtworkActions
        },
        {
            path: '/my-account/artwork-approved',
            name: 'my-account-artworks-approved',
            component: MyAccountArtworkApprovals
        },
        {
            path: '/my-account/artwork-approved/:id',
            name: 'my-account-artwork-approved',
            component: MyAccountArtworkApprovals
        },
        {
            path: '/my-account/artwork-awaiting-approval',
            name: 'my-account-artworks-awaiting-approval',
            component: MyAccountArtworkApprovals
        },
        {
            path: '/my-account/artwork-declined',
            name: 'my-account-artworks-declined',
            component: MyAccountArtworkApprovals
        },
        {
            path: '/my-account/artwork-declined/:id',
            name: 'my-account-artwork-declined',
            component: MyAccountArtworkApprovals
        },
        {
            path: '/my-account/resources-approved',
            name: 'my-account-assets-approved',
            component: MyAccountAssetsApproved
        },
        {
            path: '/my-account/resources-awaiting-approval',
            name: 'my-account-assets-awaiting-approval',
            component: MyAccountAssetsAwaitingApproval
        },
        {
            path: '/my-account/uploads',
            name: 'my-account-uploads',
            component: MyAccountUploads
        },
        {
            path: '/my-account/user-groups',
            name: 'my-account-user-groups',
            component: MyAccountUserGroups
        },
        {
            path: '/notifications',
            name: 'notifications',
            component: Notifications
        },
        {
            path: '/pod-codes',
            name: 'pod-codes',
            component: PodCodes
        },
        {
            path: '/proofs',
            name: 'proofs',
            component: Proofs
        },
        {
            path: '/proofs/:id',
            name: 'view-proof',
            component: ViewProof
        },
        {
            path: '/proofs/:id/:tab',
            name: 'view-proof-tab',
            component: ViewProof
        },
        {
            path: '/resources',
            name: 'assets',
            component: Assets
        },
        {
            path: '/resources/:filters',
            name: 'assets-filtered',
            component: Assets
        },
        {
            path: '/set-password/:code',
            name: 'set-password',
            component: SetPassword
        },
        {
            path: '/set-password/:code/:proof_id',
            name: 'set-password-proofs-guest',
            component: SetPassword
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings
        },
        {
            path: '/settings/administrator-alerts',
            name: 'alerts',
            component: Alerts
        },
        {
            path: '/settings/artwork-approvals',
            name: 'artwork-approvals',
            component: ArtworkApprovals
        },
        {
            path: '/settings/auto-approve-domains',
            name: 'pre-approved-email-domains',
            component: PreApprovedEmailDomains
        },
        {
            path: '/settings/custom-fields',
            name: 'custom-fields',
            component: CustomFields
        },
        {
            path: '/settings/deletion-requests',
            name: 'deletion-requests',
            component: DeletionRequests
        },
        {
            path: '/settings/ai-keyword-exclusions',
            name: 'detected-label-exclusions',
            component: DetectedLabelExclusions
        },
        {
            path: '/settings/expired-invites',
            name: 'expired-invites',
            component: Users
        },
        {
            path: '/settings/folders',
            name: 'folders',
            component: Folders
        },
        {
            path: '/settings/folders/archived',
            name: 'folders-archived',
            component: FoldersArchived
        },
        {
            path: '/settings/keyword-assistant',
            name: 'keyword-categories',
            component: KeywordCategories
        },
        {
            path: '/settings/languages',
            name: 'languages',
            component: Languages
        },
        {
            path: '/settings/new-users',
            name: 'new-users',
            component: Users
        },
        {
            path: '/settings/price-panels',
            name: 'price-panel-types',
            component: PricePanelTypes
        },
        {
            path: '/settings/print-orders',
            name: 'print-orders',
            component: PrintOrders
        },
        {
            path: '/settings/print-orders/awaiting-approval',
            name: 'print-orders-awaiting-approval',
            component: PrintOrders
        },
        {
            path: '/settings/print-orders/declined',
            name: 'print-orders-declined',
            component: PrintOrders
        },
        {
            path: '/settings/print-orders/archived',
            name: 'print-orders-archived',
            component: PrintOrders
        },
        {
            path: '/settings/copy-print-order-recipients',
            name: 'print-order-copy-recipients',
            component: PrintOrderCopyRecipients
        },
        {
            path: '/settings/print-order-suppliers',
            name: 'print-order-suppliers',
            component: PrintOrderSuppliers
        },
        {
            path: '/settings/profile-fields',
            name: 'profile-fields',
            component: ProfileFields
        },
        {
            path: '/settings/profile-groups',
            name: 'profile-groups',
            component: ProfileGroups
        },
        {
            path: '/settings/profile-groups/:id',
            name: 'view-profile-group',
            component: ViewProfileGroup
        },
        {
            path: '/settings/reports/artwork-created',
            name: 'reports-artwork-created',
            component: Reports
        },
        {
            path: '/settings/reports/downloads',
            name: 'reports-asset-downloads',
            component: Reports
        },
        {
            path: '/settings/reports/flyte-activity',
            name: 'reports-flytes-sent',
            component: Reports
        },
        {
            path: '/settings/reports/orders',
            name: 'reports-orders',
            component: Reports
        },
        {
            path: '/settings/reports/overview',
            name: 'reports-overview',
            component: Reports
        },
        {
            path: '/settings/reports/registrations',
            name: 'reports-registrations',
            component: Reports
        },
        {
            path: '/settings/reports/templates',
            name: 'reports-templates',
            component: Reports
        },
        {
            path: '/settings/reports/uploads',
            name: 'reports-asset-uploads',
            component: Reports
        },
        {
            path: '/settings/reports/visits',
            name: 'reports-visits',
            component: Reports
        },
        {
            path: '/settings/resource-approvals',
            name: 'asset-approvals',
            component: AssetApprovals
        },
        {
            path: '/settings/resources-due-for-review',
            name: 'asset-reviews',
            component: AssetReviews
        },
        {
            path: '/settings/resource-types',
            name: 'asset-types',
            component: AssetTypes
        },
        {
            path: '/settings/resource-types/inactive',
            name: 'asset-types-inactive',
            component: AssetTypesInactive
        },
        {
            path: '/settings/users',
            name: 'users',
            component: Users
        },
        {
            path: '/settings/user-groups',
            name: 'user-groups',
            component: UserGroups
        },
        {
            path: '/shared/:code',
            name: 'shared',
            component: Shared
        },
        {
            path: '/shared/:code/:email',
            name: 'shared-email',
            component: Shared
        },
        {
            path: '/support',
            name: 'support',
            component: Support
        },
        {
            path: '/support/:subject',
            name: 'support-subject',
            component: Support
        },
        {
            path: '/verify-email/:code',
            name: 'verify-email',
            component: VerifyEmail
        },
        {
            path: '/Connections/sso.php',
            name: 'sso-438',
            component: SingleSignOn438
        },
        {
            path: '/saml',
            name: 'sso-saml',
            component: SingleSignOnSaml
        },
        {
            path: '/features/templates/templatedownload.php',
            name: 'legacy-supplier-print-order-file-download',
            component: SupplierPrintOrderFileDownload
        }
    ]
});

/**
 * Import the rest of components
 */
require('./vue.components');

/**
 * Create app storage
 */
var storage = {
    app: null,
    is_sm_screen: false,
    is_md_screen: false,
    is_lg_screen: false,
    is_xl_screen: false,
    is_xl2_screen: false,
    is_xxl_screen: false,
    is_3xl_screen: false,
    is_4xl_screen: false,
    client: {
        asset_details_default_tab: 'download',
        template_details_default_tab: 'template-options',
        asset_type_filter_menu: true,
        hide_empty_folders: true,
        invert_search_dropdown_folder_colours: false
    },
    user: null,
    token: null,
    requested_route: null,
    previous_route: null,
    current_route: null,
    public_routes: [
        'login',
        'login-password-reset',
        'verify-email',
        'set-password',
        'set-password-proofs-guest',
        'get-new-invite-link',
        'get-new-reset-link',
        'support',
        'sso-438',
        'sso-saml',
        'legacy-supplier-print-order-file-download'
    ],
    status_info: {
        left: null,
        right: null
    },
    asset_filters: [],
    temp_filters: [],
    user_filters: [],
    folders_changed: false,
    profile_fields_changed: false,
    profiles_changed: false,
    contents: {
        asset_title: 'resource',
        description_title: 'description',
        template_title: 'template',
        terms_and_conditions: null,
        profile_group_title: 'profile group',
        profile_groups_title: 'profile groups',
        profile_title: 'profile',
        profiles_title: 'profiles',
        profile_group_description_title: 'site',
        financial_approver_title: 'financial approver',
        print_order_custom_field_1_title: null,
        print_order_custom_field_2_title: null,
        print_order_custom_field_3_title: null,
        print_order_custom_field_4_title: null,
        folder_title: 'folder',
        folders_title: 'folders',
        subfolder_title: 'subfolder',
        subfolders_title: 'subfolders',
        print_order_confirmation: null,
        select_price_panel_text: null,
        company_title: 'company'
    },
    selected_assets: [],
    current_collection: null,
    current_collection_name: '',
    view_collection_name: '',
    collection_filters: [],
    collection_selected_assets: [],
    num_expired_invites: 0,
    asset_approval_filters: [],
    share_info: {},
    login_email: null,
    login_password: null,
    editing_homepage: false,
    edit_homepage_user_group: -1,
    report_filters: [],
    report_csv_download: null,
    report_xls_download: null,
    assets_from_approval_email: [],
    pod_code_filters: [],
    adding_asset: false,
    adding_template: false,
    editing_artwork: false,
    tiny_mce_content_styles: "@import url('https://fonts.googleapis.com/css?family="
        + "Poppins:300,400,500,600,700&display=swap'); body { font-family: 'Poppins', "
        + "sans-serif; font-size: 10pt; color: rgb(73, 80, 87); }",
    profile_group_filters: [],
    view_profile_group_name: '',
    assets_info: null,
    print_order_filters: [],
    print_order_result_approvers: [],
    redirect_to_home: false,
    artwork_approval_filters: [],
    is_own_profile_group: false,
    imitate: null,
    report_start_date: null,
    proof_filters: [],
    view_proof_title: '',
    edit_image_crop_item: null,
    edit_image_crop_preset: null,
    languages: [],
    current_language: null
    /* ADD TO resetSystemVariables() !!! */
};

/**
 * Common functions
 */
function isLoggedIn () {
    const loggedOut = ((storage.user == null) || (storage.token == null));

    return !loggedOut;
}

router.beforeEach((to, from, next) => {
    // Jira R2-566 temporary redirects.
    let tempRedirect = null;
    if (to.path == '/index.php') {
        if ('stage' in to.query) {
            if (to.query.stage == 'admin/templateprintordersawaitingapproval.php') {
                tempRedirect = {
                    name: 'print-orders-awaiting-approval'
                }
            }
            if (to.query.stage == 'admin/templates/reviewgatekeepertemplatesbyuser.php') {
                tempRedirect = {
                    name: 'artwork-approvals'
                }
            }
        }
    }
    if (tempRedirect) {
        next(tempRedirect);
    } else {
        let externalRoutes = ['shared', 'shared-email'];
        storage.public_routes.forEach((item) => {
            externalRoutes.push(item);
        });
        if (
            (externalRoutes.indexOf(to.name) === -1) &&
            !isLoggedIn() &&
            (!storage.app || (document.cookie.indexOf(storage.app.client_id + '_api_token') === -1))
        ) {
            storage.requested_route = to;
            next({ name: 'login' });
        } else {
            next();
        }
        storage.previous_route = from.name;
        storage.current_route = to.name;
        storage.status_info = {
            left: null,
            right: null
        };
    }
});

/**
 * Start the app
 */
const app = new Vue({
    el: '#app',
    data: storage,
    components: { App },
    router,
    watch: {
        current_route: function (val) {
            //const appComponent = this.$root.findComponent(this, 'main-app');
            this.pinSidebar(false);
            $('body').removeClass('right-bar-enabled');
            this.hideMainNavDropdowns();
            $('.page-topnav').show();
            $('.status-bar').show();
            if ((val == 'assets-filtered') || (this.previous_route == 'assets-filtered')) {
                this.$root.$data.folders_changed = true;
            }
            if (
                (this.$root.$data.temp_filters.length > 0)
                && (
                    (val == 'assets-filtered') && (this.previous_route == 'assets')
                    || (val == 'assets') && (this.previous_route == 'assets-filtered')
                )
            ) {
                let filters = [];
                this.$root.$data.temp_filters.forEach((item) => {
                    filters.push(item);
                });
                this.$root.$data.asset_filters = filters;
                this.$root.$data.temp_filters = [];
                /*if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'assets');
                    if (component) {
                        component.getAssets();
                        console.log('get assets: query changed');
                    }
                }*/
            }
        },
        selected_assets: function (val) {
            // Update selected assets cookie
            if (this.$root.$data.user) {
                const cookieName = this.$root.$data.app.client_id + '_sr' + this.$root.$data.user.id;
                if (val.length > 0) {
                    this.$root.setCookie(cookieName, val.join(',')/*, '1D'*/);
                } else {
                    this.$cookie.delete(cookieName);
                }
            }
        },
        current_collection: function (val) {
            // Update current collection cookie
            if (this.$root.$data.user) {
                const cookieName = this.$root.$data.app.client_id + '_cc' + this.$root.$data.user.id;
                if (val) {
                    this.$root.setCookie(cookieName, val);
                } else {
                    this.$cookie.delete(cookieName);
                }
            }
        },
        editing_homepage: function (val) {
            if (val) {
                this.$root.$data.edit_homepage_user_group = -1;
            }
        },
        folders_changed: function (val) {
            if (val) {
                this.$root.$data.assets_info = null;
                this.getAssetsInfo();
            }
        }
    },
    methods: {
        resetSystemVariables: function () {
            this.$root.$data.status_info = {
                left: null,
                right: null
            };
            this.$root.$data.asset_filters = [];
            this.$root.$data.temp_filters = [];
            this.$root.$data.user_filters = [];
            this.$root.$data.folders_changed = false;
            this.$root.$data.profile_fields_changed = false;
            this.$root.$data.profiles_changed = false;
            this.$root.$data.selected_assets = [];
            this.$root.$data.current_collection = null;
            this.$root.$data.current_collection_name = '';
            this.$root.$data.view_collection_name = '';
            this.$root.$data.collection_filters = [];
            this.$root.$data.collection_selected_assets = [];
            this.$root.$data.num_expired_invites = 0;
            this.$root.$data.asset_approval_filters = [];
            this.$root.$data.share_info = {};
            this.$root.$data.login_email = null;
            this.$root.$data.login_password = null;
            this.$root.$data.editing_homepage = false;
            this.$root.$data.edit_homepage_user_group = -1;
            this.$root.$data.report_filters = [];
            this.$root.$data.report_csv_download = null;
            this.$root.$data.report_xls_download = null;
            this.$root.$data.pod_code_filters = [];
            this.$root.$data.profile_group_filters = [];
            this.$root.$data.view_profile_group_name = '';
            this.$root.$data.assets_info = null;
            this.$root.$data.print_order_filters = [];
            this.$root.$data.print_order_result_approvers = [];
            this.$root.$data.artwork_approval_filters = [];
            this.$root.$data.is_own_profile_group = false;
            this.$root.$data.imitate = null;
            this.$root.$data.proof_filters = [];
            this.$root.$data.view_proof_title = '';
            this.$root.$data.edit_image_crop_item = null;
            this.$root.$data.edit_image_crop_preset = null;
            this.$root.$data.adding_asset = false;
            this.$root.$data.adding_template = false;
            this.$root.$data.editing_artwork = false;
        },
        isPublicRoute: function () {
            return (this.$root.$data.public_routes.indexOf(this.$root.$data.current_route) > -1);
        },
        isLoggedIn: function () {
            const loggedOut = ((this.$root.$data.user == null) || (this.$root.$data.token == null));

            return !loggedOut;
        },
        getUser: async function (stopLoading = null) {
            var vm = this;
            try {
                const url = vm.$root.$data.app.api_url + '/' + vm.$root.$data.app.client_id + '/user/0/'
                    + vm.getDevice();
                const response = await axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                });
                vm.$root.$data.user = response.data.user;

                console.log('get user');
                // Set current language?
                if (vm.$root.$data.languages.length > 0) {
                    console.log('languages loaded');
                    let currentLanguage = response.data.user.language_id;
                    if (currentLanguage) {
                        console.log('user language', currentLanguage);
                        if (vm.$root.$data.current_language != currentLanguage) {
                            console.log('current language different', vm.$root.$data.current_language);
                            let found = false;
                            vm.$root.$data.languages.forEach((item) => {
                                if (item.active && (item.id == currentLanguage)) {
                                    found = true;
                                }
                            });

                            if (found) {
                                console.log('language found');
                                vm.$root.setLanguage(currentLanguage, false);
                            }
                        }
                    }
                }

                // Get selected assets.
                vm.getSelectedAssetsAndCollectionFromCookie();

                // Get assets info.
                vm.getAssetsInfo();

                // Show mega menu info modal?
                vm.showMegaMenuInfo();

                // Redirect back to requested route.
                if (vm.$root.$data.requested_route) {
                    if (vm.$root.isPrintSupplier() && (vm.$root.$data.requested_route.name == 'home')) {
                        vm.$router.push({ name: 'my-account-print-orders' });
                    } else if (vm.$root.isProofsGuest() && (vm.$root.$data.requested_route.name == 'home')) {
                        vm.$router.push({ name: 'proofs' });
                    } else {
                        vm.$router.push({
                            name: vm.$root.$data.requested_route.name,
                            params: vm.$root.$data.requested_route.params,
                            query: vm.$root.$data.requested_route.query
                        });
                    }
                    vm.$root.$data.requested_route = null;
                }
            } catch (error) {
                vm.$root.doLogout();
            }
            if (stopLoading) {
                stopLoading.user_loaded = true;
            }
        },
        getSelectedAssetsAndCollectionFromCookie: function () {
            var vm = this;
            if (!vm.$root.$data.user) {
                return null;
            }
            // Get current collection.
            let cookieName = vm.$root.$data.app.client_id + '_cc' + vm.$root.$data.user.id;
            const currentCollection = vm.$cookie.get(cookieName);
            if (currentCollection) {
                vm.$root.$data.current_collection = parseInt(currentCollection);

                return null;
            }
            // Get selected assets.
            cookieName = vm.$root.$data.app.client_id + '_sr' + vm.$root.$data.user.id;
            const selectedAssetsFromCookie = vm.$cookie.get(cookieName);
            if (selectedAssetsFromCookie) {
                const selectedAsstes = selectedAssetsFromCookie.split(',');
                vm.$root.$data.selected_assets = [];
                selectedAsstes.forEach((item) => {
                    vm.$root.$data.selected_assets.push(parseInt(item));
                });
            }
        },
        getAssetsInfo: function () {
            if (!this.hasPermission('assets_template_filter')) {
                return null;
            }
            if (!this.hasPermission('assets_access') && !this.hasPermission('templates_access')) {
                return null;
            }
            /**
             * Send request to API
             */
            const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/resources-info';
            var vm = this;

            axios({
                url: url,
                headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
            })
            .then(response => {
                vm.assets_info = response.data;
            })
            .catch(function (error) {
                //
            });
        },
        hasPermission: function (permission) {
            if (!permission || !this.$root.user) {
                return false;
            }
            if (!this.$root.user.permissions) {
                return false;
            }
            if (this.$root.user.permissions.indexOf(permission) > -1) {
                return true;
            }

            return false;
        },
        setCookie: function (name, value, expiry = null) {
            let options = { samesite: 'Lax' };
            if (expiry) {
                options.expires = expiry;
            }
            this.$cookie.set(name, value, options);
        },
        doLogout: function (redirectToLogin = true) {
            this.$cookie.delete(this.$root.$data.app.client_id + '_api_token');
            this.$root.$data.user = null;
            this.$root.$data.token = null;
            if (redirectToLogin && (this.$route.name != 'login')) {
                this.$router.push({ name: 'login' });
            }
        },
        findComponent: function (parentComponent, componentName) {
            var found = false;
            parentComponent.$children.forEach((component) => {
                if (component.component_name == componentName) {
                    found = component;
                }
                if (!found && component.$vnode) {
                    if (component.$vnode.tag.indexOf('-' + componentName) > -1) {
                        found = component;
                    }
                }
            });

            return found;
        },
        getSetting: function (key, defaultValue = null) {
            if (!(key in this.$root.$data.client)) {
                return defaultValue;
            }

            return this.$root.$data.client[key];
        },
        loginPanelClass: function() {
            var position = this.$root.getSetting('login_panel_position', 'center');
            if (position == 'left') {
                position = 'start';
            }
            if (position == 'right') {
                position = 'end';
            }

            return 'row justify-content-' + position;
        },
        getContent: function (vm, key) {
            /**
             * Send request to API
             */
            const url = vm.$root.$data.app.api_url + '/' + vm.$root.$data.app.client_id + '/content/' + key;

            axios({
                url: url
            })
            .then(response => {
                response.data.status = 200;
                vm.get_content_response = response.data;
            })
            .catch(function (error) {
                vm.get_content_response = {
                    status: error.response.status,
                    key: (error.response.data.key || null),
                    error: (error.response.data.error || 'Something went wrong, please try again')
                }
            });
        },
        initial: function (name1, name2 = null) {
            let letters = '';
            if ((typeof name1 === 'string') && (name1.length > 0)) {
                letters += name1.substring(0,1).toUpperCase();
            }
            if (name2) {
                if ((typeof name2 === 'string') && (name2.length > 0)) {
                    letters += name2.substring(0,1).toUpperCase();
                }
            }

            return letters;
        },
        ucfirst: function (text) {
            if ((typeof text === 'string') && (text.length > 0)) {
                return (text[0].toUpperCase() + text.substring(1));
            }

            return '';
        },
        /*anA: function (text) {
            const initialUpperCase = this.initial(text);
            if (initialUpperCase == '') {
                return '';
            }
            const anLetters = ['A', 'E', 'I', 'O', 'U'];
            
            return (anLetters.indexOf(initialUpperCase) > -1) ? 'an' : 'a';
        },*/
        hideMainNavDropdowns: function () {
            $('#main-nav-small-content').removeClass('show');
            const appComponent = this.findComponent(this.$root, 'main-app');
            const component = this.findComponent(appComponent, 'main-nav');
            if (component) {
                component.hideAllDropdowns();
            }
        },
        isXlScreen: function () {
            return ($('body').attr('data-xl-screen') == 'true');
        },
        addFilter: function (filtersKey, filter, addToFront = false) {
            let filters = [];
            this.$root.$data[filtersKey].forEach((item) => {
                filters.push(item);
            });
            let filterIndex = -1;
            let maxId = 0;
            const multiSelectFilters = [
                'main-keyword',
                'keyword',
                'asset_type',
                'user_group',
                'resource_id',
                'report_pg',
                'report_ug'
            ];
            const isMultiSelectFilter =
                ((filter.key.substr(0, 2) == 'cf') || (multiSelectFilters.indexOf(filter.key) > -1));
            filters.forEach((item, index) => {
                if ((item.key == filter.key) && !isMultiSelectFilter) {
                    filterIndex = index;
                }
                if (item.id > maxId) {
                    maxId = item.id;
                }
            });
            if (filterIndex > -1) {
                if (
                    (((filter.key == 'folder') || (filter.key == 'template')) && (filter.value !== null)) || filter.value
                ) {
                    filter.id = filters[filterIndex].id;
                    filters[filterIndex] = filter;
                } else {
                    filters.splice(filterIndex, 1);
                }
            } else {
                if (
                    (((filter.key == 'folder') || (filter.key == 'template')) && (filter.value !== null)) || filter.value
                ) {
                    filter.id = (maxId + 1);
                    if (addToFront) {
                        filters.unshift(filter);
                    } else {
                        filters.push(filter);
                    }
                }
            }
            this.$root.$data[filtersKey] = filters;
        },
        isFeatured: function (feature) {
            if (!this.$root.client.features) {
                return false;
            }

            return (this.$root.client.features.indexOf(feature) > -1);
        },
        unHighlightErrors: function (field, itemId = '') {
            $('#' + field + '-label' + itemId).removeClass('text-danger');
            $('#' + field + itemId).removeClass('field-error');
        },
        getDateTimeFormatted: function (vm, id, dateTime, format = 'd/m/Y \\a\\t g:ia') {
            if (!id || !dateTime) {
                return null;
            }
            const inputDate = dateTime.substr(0, 10);
            const inputTime = dateTime.substr(11, 8);
            dateTime = inputDate;
            if (inputTime) {
                dateTime += 'T' + inputTime;
            }

            /**
             * Send request to API
             */
            const url = vm.$root.$data.app.api_url + '/' + vm.$root.$data.app.client_id + '/date-time-formatted/' + id
                + '/' + dateTime + '/' + ((format == 'time_ago') ? format : btoa(format));

            axios({
                url: url,
            })
            .then(response => {
                response.data.status = 200;
                vm.get_date_time_formatted_response = response.data;
            })
            .catch(function (error) {
                vm.get_date_time_formatted_response = {
                    status: error.response.status,
                    error: (error.response.data.error || 'Something went wrong, please try again')
                }
            });
        },
        formatFileSize: function (bytes) {
            let formatted = '';
            if (bytes > (1000 * 1000 * 100)) {
                const gb = (bytes / (1000 * 1000 * 1000));
                formatted = gb.toFixed(1).toString() + 'GB';

                return formatted.replace(/\.0/g, '');
            }
            if (bytes > (1000 * 100)) {
                const mb = (bytes / (1000 * 1000));
                formatted = mb.toFixed(1).toString() + 'MB';

                return formatted.replace(/\.0/g, '');
            }
            const kb = Math.ceil(bytes / 1000);

            return kb + 'KB';
        },
        imagePlaceholder: function (filename) {
            if (!filename) {
                return '/images/file-icon.png';
            }
            let ext = null;
            const parts = filename.split('.');
            if (parts.length > 1) {
                ext = parts[parts.length - 1].toLowerCase();
            }
            if (ext == 'ai') {
                return '/images/ai-icon.png';
            }
            if (ext == 'avi') {
                return '/images/avi-icon.png';
            }
            if (ext == 'css') {
                return '/images/css-icon.png';
            }
            const docExt = 'doc,docx,dot,docm,dotx,dotm,odt';
            if (docExt.indexOf(ext) > -1) {
                return '/images/doc-icon.png';
            }
            if (ext == 'eps') {
                return '/images/eps-icon.png';
            }
            if (ext == 'gif') {
                return '/images/gif-icon.png';
            }
            if (ext == 'html') {
                return '/images/html-icon.png';
            }
            if (ext == 'indd') {
                return '/images/indd-icon.png';
            }
            const jpgExt = 'jpg,jpeg';
            if (jpgExt.indexOf(ext) > -1) {
                return '/images/jpg-icon.png';
            }
            if (ext == 'mov') {
                return '/images/mov-icon.png';
            }
            if (ext == 'mp3') {
                return '/images/mp3-icon.png';
            }
            if (ext == 'pdf') {
                return '/images/pdf-icon.png';
            }
            if (ext == 'png') {
                return '/images/png-icon.png';
            }
            const pptExt = 'ppt,pps,pptx,pptm,potx,potm,ppam,ppsx,ppsm,sldx,sldm,odp';
            if (pptExt.indexOf(ext) > -1) {
                return '/images/ppt-icon.png';
            }
            if (ext == 'psd') {
                return '/images/psd-icon.png';
            }
            if (ext == 'txt') {
                return '/images/txt-icon.png';
            }
            if (ext == 'wav') {
                return '/images/wav-icon.png';
            }
            const xlsExt = 'xls,xlsx,csv,xlt,xlm,xlsm,xltx,xltm,xlsb,xla,xlam,xll,xlw,ods';
            if (xlsExt.indexOf(ext) > -1) {
                return '/images/xls-icon.png';
            }
            if (ext == 'zip') {
                return '/images/zip-icon.png';
            }

            return '/images/file-icon.png';
        },
        clearNotification: function (type, id, action = null, itemId = null) {
            const appComponent = this.$root.findComponent(this.$root, 'main-app');
            if (appComponent) {
                const component = this.$root.findComponent(appComponent, 'main-nav');
                if (component) {
                    component.clearNotification(type, id, action, itemId);
                }
            }
        },
        getDevice: function () {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))
            ) {
                return 'Mobile';
            }

            return 'Desktop';
        },
        pinSidebar: function (pin) {
            if (pin) {
                $('.resourcebase').addClass('pinned');

                return null;
            }
            $('.resourcebase').removeClass('pinned');
        },
        showMegaMenuInfo: function (delay = true) {
            if (!this.$root.$data.user) {
                return null;
            }
            if (!this.$root.$data.user.show_mega_menu_info) {
                return null;
            }
            if (delay) {
                var vm = this;
                setTimeout(function () {
                    vm.showMegaMenuInfo(false);
                }, 3000);

                return null;
            }
            if (!this.$root.$data.is_md_screen) {
                return null;
            }
            $('#mega-menu-info-modal').modal('show');
        },
        stopImitating: function () {
            const appComponent = this.$root.findComponent(this, 'main-app');
            if (this.$root.editing_artwork) {
                appComponent.hideArtworkEditor();
            }
            if (this.$root.adding_asset) {
                const assetAddComponent = this.$root.findComponent(appComponent, 'asset-add-form');
                if (assetAddComponent) {
                    assetAddComponent.onClose(true);
                }
            }
            if (this.$root.adding_template) {
                const templateAddComponent = this.$root.findComponent(appComponent, 'template-add-form');
                if (templateAddComponent) {
                    templateAddComponent.onClose(true);
                }
            }
            let adminUser = {};
            for (const [key, value] of Object.entries(this.$root.$data.imitate.admin_user)) {
                adminUser[key] = value;
            };
            this.$root.$data.user = adminUser;
            this.$root.$data.token = this.$root.$data.imitate.admin_token.toString();
            this.$root.resetSystemVariables();
            this.$root.getSelectedAssetsAndCollectionFromCookie();
            this.$root.getAssetsInfo();
            if (this.$route.name == 'home') {
                const homeComponent = this.$root.findComponent(appComponent, 'home');
                if (homeComponent) {
                    homeComponent.getPanels();
                }

                return null;
            }
            this.$router.push({ name: 'home' });
        },
        hasReCaptcha: function () {
            if ($('#app').attr('data-recaptcha-site-key')) {
                return true;
            }

            return false;
        },
        hideReCaptchaBadge: async function () {
            if (!this.$root.hasReCaptcha()) {
                return null;
            }
            await this.$recaptchaLoaded();
            
            this.$recaptchaInstance.hideBadge();
        },
        showMaintenanceMessage: function (message = null) {
            $('.modal').modal('hide');
            this.$root.$data.app.maintenance_message = message || 'System is under maintenance.';
            this.$root.$data.app.maintenance = true;

            setTimeout(function () {
                window.location.href='/';
            }, 1000 * 60 * 5);

            if (this.$route.name != 'login') {
                this.$router.push({ name: 'login' });
            }

            setTimeout(function () {
                $('body').removeClass('login-body');
            }, 200);
        },
        isProofsGuest: function () {
            if (!this.$root.isLoggedIn()) {
                return false;
            }
            if (!this.$root.$data.client) {
                return false;
            }
            if (!this.$root.$data.client.proofs_guest_role) {
                return false;
            }
            const proofsGuestRole = parseInt(this.$root.$data.client.proofs_guest_role);

            return this.$root.$data.user.role == proofsGuestRole;
        },
        isPrintSupplier: function () {
            if (!this.$root.isLoggedIn()) {
                return false;
            }
            if (!this.$root.$data.user) {
                return false;
            }

            return (this.$root.$data.user.role_name.toLowerCase().indexOf('supplier') > -1);
        },
        getUploadFileId: function (uploadFile) {
            let id = uploadFile.upload.uuid;
            let parts = uploadFile.name.split('.');
            if (parts.length > 1) {
                id += ('.' + parts.pop());
            }

            return id;
        },
        text: function (originalText, params = null, ucfirst = false, contentKey = null) {
            if (!originalText) {
                return '???';
            }
            let text = originalText;
            if (
                (this.$root.$data.languages.length > 1)
                && (this.$root.$data.current_language !== null)
                && (this.$root.$data.current_language > 1)
            ) {
                if (!contentKey) {
                    contentKey = this.getContentKey(text);
                }
                if (contentKey) {
                    if (this.$root.$data.contents[contentKey + '_' + this.$root.$data.current_language]) {
                        text = this.$root.$data.contents[contentKey + '_' + this.$root.$data.current_language];
                    }
                } else {
                    console.log('Content not found:', text);
                }
            }
            if (!text) {
                return '???';
            }
            if (params) {
                for (const [key, value] of Object.entries(params)) {
                    let regExp = new RegExp('{' + key + '}', "g");
                    text = text.replace(regExp, value);
                }
            }
            text = text.replace(/{/g, '').replace(/}/g, '');
            if (ucfirst) {
                return this.$root.ucfirst(text);
            }

            return text;
        },
        parseApiErrors: function (field, errors, replace = null) {
            let parsedErrors = [];
            errors.forEach((error) => {
                if (error == 'The ' + field.replace(/_/g, ' ') + ' is incorrect.') {
                    parsedErrors.push(
                        this.$root.text(
                            'The {field_title} is incorrect.',
                            { field_title: this.$root.getApiErrorFieldTitle(field, replace) },
                            true
                        )
                    );
                } else if (error == 'The ' + field.replace(/_/g, ' ') + ' field is required.') {
                    parsedErrors.push(
                        this.$root.text(
                            'The {title} field is required.',
                            { title: this.$root.getApiErrorFieldTitle(field, replace) },
                            true
                        )
                    );
                } else {
                    parsedErrors.push(this.$root.text(error, null, true));
                }
            });

            return parsedErrors.join('<br>') + '<br>';
        },
        getApiErrorFieldTitle: function (field, replace) {
            if (replace) {
                if (replace[field]) {
                    if (this.$root.contents[replace[field]]) {
                        return this.$root.text(this.$root.contents[replace[field]], null, false, replace[field]);
                    }

                    return this.$root.text(replace[field]);
                }
            }

            return this.$root.text(field.replace(/_/g, ' '));
        },
        getContentKey: function (content) {
            let found = null;
            for (const [key, value] of Object.entries(this.$root.$data.contents)) {
                if (value) {
                    if (value.toLowerCase() == content.toLowerCase()) {
                        found = key;

                        return key;
                    }
                }
            }

            return found;
        },
        getAvailableLanguages: function () {
            let languages = [];
            this.$root.$data.languages.forEach((item) => {
                if (item.active) {
                    languages.push(item);
                }
            });

            return languages;
        },
        getCurrentLanguageCode: function () {
            let itemIndex = -1;
            this.$root.$data.languages.forEach((item, index) => {
                if (item.id == this.$root.$data.current_language) {
                    itemIndex = index;
                }
            });

            return (itemIndex > -1) ? this.$root.$data.languages[itemIndex].code.substring(0, 2).toUpperCase() : 'EN';
        },
        setLanguage: function (id, updateUserLanguage = true) {
            this.$root.$data.current_language = id;
            this.$root.setCookie(this.$root.$data.app.client_id + '_language', id, '1Y');
            const appComponent = this.$root.findComponent(this, 'main-app');
            const component = this.$root.findComponent(appComponent, 'main-nav');
            if (component) {
                component.hideAllDropdowns();
            }
            if (!this.$root.isLoggedIn() || !updateUserLanguage || this.$root.$data.imitate) {
                return null;
            }

            /**
             * Send change user language request to API.
             */
            const data = {
                language_id: id
            };

            const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/user/change-language';
            var vm = this;

            axios({
                method: 'put',
                url: url,
                data: data,
                headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
            })
            .then(response => {
                // Do anything?
            })
            .catch(function (error) {
                // Do anything?
            });
        },
        isCurrentLanguage: function (id) {
            if (!this.$root.$data.current_language && (id == 1)) {
                return true;
            }

            return (this.$root.$data.current_language == id);
        }
    }
});